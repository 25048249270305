@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/base/_mixins.scss";

@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_vars.scss";

@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/_local-fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/base/_mixins.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www/ortodont-verstka/src/scss/global/_vars.scss";



html {
  font-family: 'Montserrat',
    sans-serif;
}

.bg {
  &-primary {
    &-10 {
      background-color: $color-primary-10;
    }
  }
}

.header {
  &__address {
    font-size: 16px;
    font-weight: 700;
    color: $color-primary;
    margin-bottom: 2px;
  }

  &__phone {
    font-size: 16px;
    font-weight: 700;
    color: $color-primary;
    margin-bottom: 2px;
  }

  &__sublink {
    font-size: 12px;
    font-weight: 500;
    color: #5b5c5c;

    &--mobile {
      color: #fff;
    }
  }

  &__button {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }
}

.main-nav {
  background-color: #eaecea;

  &__list {
    display: flex;
  }

  &__item {}

  &__link {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1F354F;
    padding: 20px 0;
    transition: .1s ease-in-out;

    &--active,
    &:hover {
      color: $color-primary;
    }
  }
}

.hero-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  &__label {
    display: inline-block;
    width: max-content;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: $color-primary;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;
  }

  &__header {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
  }

  &__text {
    color: #fff;

    ul {
      list-style: none;
      // padding-left: 1em;
    }

    li {
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      &:before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: 1.5em;
        width: 1.5em;
        position: relative;
        top: -3px;
        background-image: url('../img/accept.svg'); // Fallback PNG
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em;
      }
    }
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }

  &__disclamer {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  &__price {
    color: #f77b55;
    font-size: 42px;
    font-weight: 700;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 100px;

    @include respond-to(medium) {
      font-size: 24px;
    }
  }
}

.tile-list {
  li {
    border-radius: 15px;
    padding: 20px 30px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.section {
  &__header {
    color: $color-primary;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}

.service-card {
  &__item {
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .2s ease-in-out;

    background-position: 110% center;
    background-size: contain;
    background-repeat: no-repeat;

    &--small {
      background-position: 220% center;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #1F354F;

    span {
      color: $color-primary;
    }
  }

  &__price {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #1F354F;

    span {
      color: $color-primary;
      font-weight: 700;
    }
  }

  &__button {
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: $color-primary;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: darken($color-primary, 10%);
    }
  }
}

.conversy-block {
  &__wrapper {
    padding: 40px;
    background-color: $color-secondary;
    border-radius: 20px;
  }

  &__title {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__button {
    color: darken($color: $color-secondary, $amount: 10%);
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: $color-primary;
    }
  }
}

.doctor-card {
  &__item {
    height: 480px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    transition: .2s ease-in-out;
    position: relative;

    &:hover {
      transform: scale(1.05);
    }

  }

  &__experience {
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: darken($color-secondary, 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;

    span {
      font-size: 26px;
      font-weight: 800;
    }
  }

  &__info {
    padding: 200px 30px 30px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &__name {
    color: #1f354f;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__desc {
    color: #5b5c5c;
    font-size: 16px;
    font-weight: 400;
  }

}

.rating-card {
  &__item {
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__value {
    color: $color-primary;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__desc {
    color: #1F354F;
    font-size: 16px;
    font-weight: 400;
  }
}

.carousel {
  &__img {
    // height: 300px;
    // background-color: green;
    border-radius: 15px;
    overflow: hidden;

    @include respond-to(medium) {
      height: 250px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__pagination {
    top: 110%;
  }
}

// Кнопки для SwiperJS
.swiper {
  &-button {
    &-prev {
      display: block;
      width: 80px !important;
      height: 80px !important;
      background-color: #f5c712;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      position: absolute;
      top: calc(50% - 85px);
      z-index: 1;
      border-radius: 100px;

      left: 0;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='17px' height='12px' viewBox='0 0 17 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -814.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(15.200000, 15.600000) rotate(-180.000000) translate(-15.200000, -15.600000) translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }

    &-next {
      display: block;
      width: 80px !important;
      height: 80px !important;
      background-color: #f5c712;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      position: absolute;
      top: calc(50% - 85px);
      z-index: 1;
      border-radius: 100px;

      right: 0;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -813.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }

    &-disabled {
      opacity: 0 !important;
    }
  }
}

.swiper {
  &-container {
    display: none;

    &.swiper-container-initialized {
      display: block;
    }
  }

  &-slide {
    @include respond-to(medium) {
      width: auto !important;
    }
  }

  &-pagination {
    margin-top: 10px;
    position: relative !important;
    bottom: 0 !important;

    &-bullet {
      width: 10px !important;
      height: 10px !important;

      &-active {
        background: $color-primary !important;
      }
    }
  }

  &-button {
    &-prev {
      display: block;
      width: 50px !important;
      height: 50px !important;
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(50% - 15px);
      z-index: 1;
      border-radius: 100px;

      left: 20px;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='17px' height='12px' viewBox='0 0 17 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -814.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(15.200000, 15.600000) rotate(-180.000000) translate(-15.200000, -15.600000) translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }

    &-next {
      display: block;
      width: 50px !important;
      height: 50px !important;
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(50% - 15px);
      z-index: 1;
      border-radius: 100px;

      right: 20px;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -813.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }
  }

}

.section-contacts {
  &__card {
    border-radius: 20px;
    padding: 30px 40px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }

  &__header {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__phone {
    display: block;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__address {
    display: block;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__work-time {
    display: block;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;

    p {
      margin-bottom: 5px;
    }

    span {
      font-weight: 700;
    }
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }
}

.section-doctor {
  &__name {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 18px;
    font-weight: 400;
  }
}

.installment-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  &__header {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 80px;
    text-align: center;
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }
}